import '../sdist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';

import Alpine from 'alpinejs'
import { dinero, add, multiply, allocate, toDecimal } from 'dinero.js';
import { EUR } from '@dinero.js/currencies';
import htmx from 'htmx.org';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.esm.js';
import 'swiper/swiper-bundle.css';

import Swiper, { Navigation, Pagination, Thumbs, Autoplay} from 'swiper';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import { off } from 'process';
import { doc } from 'prettier';

Alpine.plugin(collapse);


window.Alpine = Alpine
window.htmx = htmx;


function initMap(zoom, center){
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: zoom || 11,
    center: center | { lat: 25.684411, lng: -100.317283 }
  });
  var mapReadyEvent = new CustomEvent('map-ready');
  window.dispatchEvent(mapReadyEvent);
}


document.addEventListener('alpine:init', () => {

  Alpine.data('LoadInvoice', () => ({
    loading: true,
    timeout: 1000,
    load() {
       // exists in the DOM
      this.loading = this.$el.querySelector('#InvoiceStatus') !== null;

      if (this.loading) {

        // increment the timeout until we hit 5 seconds
        if (this.timeout < 5000) {
          this.timeout = this.timeout + 1000;
        }

        htmx.trigger(this.$el, 'htmx:load');
        // set a timeout to check again in 5 seconds
        setTimeout(() => {
          this.load();
        }, this.timeout);
      }
    },
    init() {
      this.load();
    }
  }));

  Alpine.data('addTicket', (initialItem) => ({
    currency: EUR,
    item: initialItem,
    amount: 1,

    itemPrice() {
      return toDecimal(dinero({
        amount: this.item.price,
        currency: this.currency,
      }), ({ value, currency }) => `${value} ${currency.code}`);
    },

    add() {
      const currentAmount = this.$store.tickets.items.length;
      for (let i = 0; i < this.amount; i++) {
        Alpine.store('tickets').add({
          salut: '',
          title: '',
          lastname: '',
          firstname: '',
          company: '',
          price_id: this.item.id,
          price: this.item.price,
          name: this.item.name,
          amount: 1,
        });
      }
      const editNext = () => this.$dispatch('edit-ticket', { index: currentAmount });
      this.$nextTick(editNext);
      this.amount = 1;
    },

  }));

  Alpine.data('ticketEdit', (itemIndex) => ({
    show: false,
    myindex: itemIndex,
    salut: '', title: '', lastname: '', firstname: '',
    company: '',
    validate: itemIndex === 0,
    index: itemIndex,

    valid_firstname() {
      return this.firstname.length > 0;
    },

    valid_lastname() {
      return this.lastname.length > 0;
    },
    valid_salut() {
      return this.salut.length > 0;
    },

    valid() {
      return this.valid_firstname() && this.valid_lastname() && this.valid_salut();
    },

    save() {
      const item = this.$store.tickets.items[this.index];
      this.validate = true;
      if (item && this.valid()) {
        item.salut = this.salut;
        item.title = this.title;
        item.lastname = this.lastname;
        item.firstname = this.firstname;
        item.company = this.company;
        this.show = false;
      }
    }
  }));

  Alpine.data('Form', () => ({
    error: false,
    submit() {
      let data = new FormData(this.$el);
      this.$store.tickets.processing = true;
      fetch(this.$el.getAttribute('action'), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',

        },
        body: data,
      }).then(response => response.json()).then(data => {
        console.log(data);
        if (data.success) {
          window.location.href = data.redirect;
        }
      })
			.catch(e => {
        console.error(e);
				this.error = true;
			})
    }
  }));

  Alpine.data('ticketCart', () => ({
    currency: EUR,
    showOverlay: false,

    format(price) {
      return toDecimal(price, ({ value, currency }) => `${value} ${currency.code}`);
    },
    ticketName(item) {
      return [item.salut, item.title, item.firstname, item.lastname].filter(n => n).join(" ");
    },

    ticketHasData(item) {
      return [item.salut, item.title, item.firstname, item.lastname].filter(n => n).length;
    },

    convertedItems() {

      return this.$store.tickets.items.map((item) => ({
        ...item,
        price: dinero({
          amount: item.price,
          currency: this.currency,
        }),
      }));
    },

    calculatedItems() {
      const zero = dinero({ amount: 0, currency: this.currency });

      return this.convertedItems().reduce(
        (acc, item) => {
          const count = acc.count + item.amount;
          const subtotal = add(acc.subtotal, multiply(item.price, item.amount));

          return { count, subtotal };
        },
        { count: 0, subtotal: zero }
      );
    },
    shipping() {
      const zero = dinero({ amount: 0, currency: this.currency });
      return zero;
    },

    total() {
      return [
        this.calculatedItems().subtotal,
        this.shipping(),
      ].reduce(add);
    },

    vatAmount() {
      const vatRate = Alpine.store('vatRate');
      const [vatAmount] = allocate(this.total(), [
        100 - 10000 / (100 + vatRate),
        10000 / (100 + vatRate),
      ]);

      return vatAmount;
    },

    submit() {
      this.$store.tickets.validate = true;
      if (this.$store.tickets.valid() && this.convertedItems().length > 0) {
        this.$dispatch('checkout');
      }
    }
  }));

  Alpine.store('vatRate', 19);

  Alpine.store('tickets', {
    email: '',
    validate: false,
    billing: false,
    shipping_same_as_billing: true,
    items: [],
    shipping_name: '',
    shipping_additional: '',
    shipping_street: '',
    shipping_postal: '',
    shipping_city: '',
    salut: '',
    title: '',
    firstname: '',
    lastname: '',
    company: '',
    additional: '',
    street: '',
    postal: '',
    city: '',
    processing: false,
    limit: null,

    valid_email() {
      return this.email.length > 0;
    },

    valid_salut() {
      return this.salut.length > 0;
    },

    valid_firstname() {
      return this.firstname.length > 0;
    },

    valid_lastname() {
      return this.lastname.length > 0;
    },

    valid_company() {
      return this.company.length > 0;
    },

    valid_street() {
      return this.street.length > 0;
    },

    valid_postal() {
      return this.postal.length > 0;
    },

    valid_city() {
      return this.city.length > 0;
    },

    valid_shipping_name() {
      return this.shipping_same_as_billing || this.shipping_name.length > 0;
    },
    valid_shipping_street() {
      return this.shipping_same_as_billing || this.shipping_street.length > 0;
    },
    valid_shipping_postal() {
      return this.shipping_same_as_billing || this.shipping_postal.length > 0;
    },
    valid_shipping_city() {
      return this.shipping_same_as_billing || this.shipping_city.length > 0;
    },

    valid_limit() {
      return this.limit === null || this.limit >= this.items.length;
    },

    valid() {
      const billing_is_valid = [
        this.valid_salut(), this.valid_firstname(), this.valid_lastname(), this.valid_company(), this.valid_street(), this.valid_postal(), this.valid_city(),
      ].every(n => n);

      const shipping_is_valid = [
        this.valid_shipping_name(), this.valid_shipping_street(), this.valid_shipping_postal(), this.valid_shipping_city(),
      ].every(n => n);

      return this.valid_email() && billing_is_valid && shipping_is_valid && this.valid_limit();
    },

    set_limit(limit) {
      this.limit = limit;
    },

    add(item) {
      this.items.push(item);
    },

    remove(index) {
      this.items.splice(index, 1);
    },
  });
})

const lightbox = new PhotoSwipeLightbox({
  gallerySelector: '.gallery-line',
  childSelector: '.pswp-gallery__item',
  pswpModule: PhotoSwipe,

  // Optional padding for images,
  // note that this is an option of PhotoSwipe, not a plugin
  paddingFn: (viewportSize) => {
    return {
      top: 30, bottom: 30, left: 70, right: 70
    }
  },
});

const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
  // Plugins options, for example:
  type: 'auto',
});

// make sure you init photoswipe core after plugins are added
lightbox.init();


let sharedAutoplayRunning = true;
const swipers = [];

function initializeSwiper() {
  document.querySelectorAll('.swiper-tcc').forEach((swiperEl) => {
    new Swiper(swiperEl, {
      modules: [Navigation, Pagination, Autoplay],
      loop: true,
      autoplay: {
        delay: 8500,
        disableOnInteraction: false,
      },
      autoHeight: false,
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        bulletActiveClass: '!bg-tcc swiper-pagination-bullet-active'
      },
      on: {
        init: function () {
          swipers.push(this);

          this.el.addEventListener('mouseenter', function () {
            sharedAutoplayRunning = false;
            swipers.forEach(s => s.autoplay.stop());
          });

          this.el.addEventListener('mouseleave', function () {
            sharedAutoplayRunning = true;
            swipers.forEach(s => s.autoplay.start());
          });
        },
      },
    });
  });

  // Check screen size and control autoplay
  if (window.innerWidth >= 768) {
    swipers.forEach(s => s.autoplay.start());
  } else {
    swipers.forEach(s => s.autoplay.stop());
  }
}

// Re-check on window resize
window.addEventListener('resize', () => {
  if (window.innerWidth >= 768) {
    if (!sharedAutoplayRunning) {
      sharedAutoplayRunning = true;
      swipers.forEach(s => s.autoplay.start());
    }
  } else {
    if (sharedAutoplayRunning) {
      sharedAutoplayRunning = false;
      swipers.forEach(s => s.autoplay.stop());
    }
  }
});

initializeSwiper();


if (document.querySelector('.company-groups')) {
  // for each group
  document.querySelectorAll('.company-groups').forEach((group) => {
    // create a new swiper
    console.log(group.querySelector(".company-button-next"));
    let companyslider = new Swiper(group.querySelector('.swiper-companies'), {
      modules: [Navigation],
      /*autoplay: {delay: 5000},
      mousewheel: true,*/
      slidesPerView: 1,
      autoHeight: false,
      freeMode: true,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: group.querySelector(".company-button-next"),
        prevEl: group.querySelector(".company-button-prev"),
      },
      breakpoints: {
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      }

    });

    let swipercdetail = new Swiper(group.querySelector('.swiper-companies-detail'), {
      modules: [Navigation, Thumbs],
      spaceBetween: 10,
      navigation: {
        nextEl: group.querySelector(".large-button-next"),
        prevEl: group.querySelector(".large-button-prev"),
      },
      thumbs: {
        swiper: companyslider,
        slideThumbActiveClass: 'text-tcc-orange',
      },
    });
  });

}

/*** Swiper - Image Slider ***/
const galleryswiper = new Swiper('.gallery-main', {
  modules: [Navigation, Pagination],
  loop: true,
  /*mousewheel: true,*/
  autoHeight: false,
  pagination: {
    clickable: true,
    el: '.swiper-pagination',
    bulletActiveClass: '!bg-tcc swiper-pagination-bullet-active'
  },
});

function isWebKit() {
  return /WebKit/.test(navigator.userAgent);
}


Alpine.data('AreaSlider', (fieldname, title, value=0, active=true, max=500) => ({
  active: active,
  value: value,
  min: 0,
  max: max,
  step: 1,
  title: title,
  fieldname: fieldname,
  isWebKit: isWebKit(),
  percentage() {
    return (this.value / this.max) * 100;
  },
  track_background() {
    if (this.isWebKit) {
      return {
        background: 'linear-gradient(to right, #d9db17 0%, #d9db17 ' + this.percentage() + '%, #e5e7eb ' + this.percentage() + '%, #e5e7eb 100%)'
      };
    }
    return {};
  },
}));

/*** Form - Range ***/
Alpine.data('range', () => ({
  minprice: 0,
  maxprice: 10000,
  min: 0,
  max: 10000,
  minthumb: 0,
  maxthumb: 0,
  mintrigger() {
    this.validation();
    this.minprice = Math.min(this.minprice, this.maxprice - 500);
    this.minthumb = ((this.minprice - this.min) / (this.max - this.min)) * 100;
  },
  maxtrigger() {
    this.validation();
    this.maxprice = Math.max(this.maxprice, this.minprice + 200);
    this.maxthumb = 100 - (((this.maxprice - this.min) / (this.max - this.min)) * 100);
  },
  validation() {
    if (/^\d*$/.test(this.minprice)) {
      if (this.minprice > this.max) {
        this.minprice = 9500;
      }
      if (this.minprice < this.min) {
        this.minprice = this.min;
      }
    } else {
      this.minprice = 0;
    }
    if (/^\d*$/.test(this.maxprice)) {
      if (this.maxprice > this.max) {
        this.maxprice = this.max;
      }
      if (this.maxprice < this.min) {
        this.maxprice = 200;
      }
    } else {
      this.maxprice = 10000;
    }
  }

}));



Alpine.start()
